<template>
  <div class="q-mt-md">
    <div>
      <q-input
        :model-value="store"
        :label="$t('Store name')"
        standout="bg-teal text-white"
        required
        @update:model-value="handleChange"
      />
    </div>

    <div v-if="!noConnectBtn" class="text-center border-top q-pt-md">
      <q-btn
        color="light-blue-9"
        style="min-width: 30%;"
        text-color="white"
        :label="$t('Connect')"
        no-caps
        unelevated
        @click="handleConnect"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopifyForm',
  emits: ['submit'],
  props: {
    noConnectBtn: {
      type: Boolean,
      default () {
        return false
      }
    },
    integration: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      store: ''
    }
  },
  methods: {
    handleChange (store) {
      this.store = store
      this.$emit('change', { store })
    },
    handleConnect () {
      return this.$service.iSource.installShopify({ store: this.store })
        .then(item => {
          this.$emit('submit', item)
        })
    }
  }
}
</script>
