<template>
  <q-dialog
    v-model="isOpen"
    :maximized="isFullscreen"
  >
    <q-card
      v-if="isOpen"
      class="relative"
      :style="isOpen && style[integration.id] ? style[integration.id] : style.main"
    >
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6">
          {{ integration.name + ' ' + $t('Integration') }}
        </div>

        <q-space />

        <q-btn
          icon="close"
          flat
          round
          dense
          @click="close"
        />
      </q-card-section>

      <q-card-section>
        <component
          :is="specialIntegrations[integration.id] || specialIntegrations.main"
          v-bind="{ integration }"
          @submit="handleSubmit"
        />
      </q-card-section>

      <q-card-section
        v-if="loadingNow || sourcesLoading"
        class="absolute text-primary col-12 row fit justify-center items-center"
        style="top: 0; background: #ffffff63;"
      >
        <q-spinner
          color="light-blue-9"
          size="3rem"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
// Components
import BaseSourceForm from './BaseSourceForm'
import ShopifyForm from './ShopifyForm'

export default {
  name: 'SourceModal',
  emits: ['submit'],
  components: {
    BaseSourceForm,
    ShopifyForm
  },
  data () {
    return {
      integration: null,
      isOpen: false,
      isFullscreen: false,
      style: {
        121: 'min-width: 30vw;',
        main: 'min-width: 45vw;'
      },
      specialIntegrations: {
        121: 'shopify-form',
        main: 'base-source-form'
      }
    }
  },
  computed: {
    ...mapGetters([
      'loadingNow',
      'sourcesLoading'
    ])
  },
  methods: {
    open (integration) {
      this.integration = integration
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    handleSubmit (item) {
      this.close()
      this.$emit('submit', item)
    }
  }
}
</script>
