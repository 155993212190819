<template>
  <div>
    <div>
      <div class="row">
        <div class="col q-pa-xs">
          <search @submit="handleSearchSubmit" />
        </div>

        <div class="q-px-xs q-py-md" style="min-width: 100px;">
          <q-btn
            color="dark"
            text-color="white"
            class="fit"
            :label="$t('Filter')"
            no-caps
            unelevated
            @click="toggleFilters"
          />
        </div>
      </div>

      <filter-collapse
        :is-open="isOpen"
        :options="{
          defaultFilter: filter,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="toggleFilters"
      />

      <div class="row">
        <div
          v-if="source"
          class="col-6 col-md-3 q-pa-sm"
        >
          <div class="source-card active full-height">
            <div
              class="text-center q-pa-xs"
              style="height: 150px"
            >
              <img
                v-if="source.image"
                style="height: 100%; width: 100%; object-fit: contain;"
                :src="source.image"
                :alt="source.name"
              >

              <div
                v-else
                class="row justify-center items-center text-center full-height text-subtitle1"
              >
                {{ source.name }}
              </div>
            </div>

            <div class="source-card__body">
              <q-icon
                name="check"
                color="light-blue-9"
                size="3rem"
              />
            </div>
          </div>
        </div>

        <div
          v-for="item in loadedSourceIntegrations"
          :key="item.id"
          class="col-6 col-md-3 q-pa-sm"
          :class="getIsVisible(item)"
        >
          <div class="source-card full-height">
            <div
              class="source-card__head text-center q-pa-sx"
              style="height: 150px"
            >
              <img
                v-if="item.image"
                style="height: 100%; width: 100%; object-fit: contain;"
                :src="item.image"
                :alt="item.name"
              >

              <div
                v-else
                class="row justify-center items-center text-center full-height text-subtitle1"
              >
                {{ item.name }}
              </div>
            </div>

            <div class="source-card__body">
              <div class="full-width">
                <h5 class="q-my-none">
                  {{ item.name }}
                </h5>

                <q-btn
                  color="light-blue-9"
                  text-color="white"
                  size="sm"
                  :label="$t('Setup')"
                  no-caps
                  unelevated
                  @click="handleSetup(item)"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!isDisabledObs"
          v-observe-visibility="loadNextSourceIntegrations({ page: sourceIntegrationsPage + 1, search, per_page: 25, filter: [{ type: 'eq', field: 'state', value: 'active' }] })"
        />

        <div
          v-if="isLoading"
          class="text-primary col-12 row fit justify-center items-center"
          style="min-height: 260px;"
        >
          <q-spinner
            color="light-blue-9"
            size="3rem"
          />
        </div>
      </div>

      <source-modal
        ref="sourceModal"
        @submit="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Directives
import { ObserveVisibility } from 'vue-observe-visibility'

// Components
import Search from '../search/Search.vue'
import SourceModal from './SourceModal.vue'
import FilterCollapse from '../filters/FilterCollapse.vue'

export default {
  name: 'SourceIntegration',
  emits: ['submit'],
  components: {
    Search,
    SourceModal,
    FilterCollapse
  },
  directives: {
    ObserveVisibility
  },
  data () {
    return {
      isOpen: false,
      loadedSourceIntegrations: [],
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'state',
        'id',
        'name'
      ],
      source: null,
      search: '',
      isLoading: false,
      filter: [{ type: 'eq', field: 'state', value: 'active' }]
    }
  },
  computed: {
    ...mapGetters([
      'sourceIntegrations',
      'sourceIntegrationsPage',
      'sourceIntegrationsTotalPages',
      'totalSourceIntegrationsNumber',
      'sources'
    ]),
    isDisabledObs () {
      if (this.sourceIntegrationsPage >= this.sourceIntegrationsTotalPages) {
        return true
      }

      return this.isLoading
    }
  },
  watch: {
    sourceIntegrations () {
      this.handleSourceIntegrationsChange()
    }
  },
  mounted () {
    const query = {
      page: 1,
      per_page: 25,
      filter: []
    }

    this.loadNextSourceIntegrations(query)
  },
  methods: {
    ...mapActions([
      'loadSourceIntegrations'
    ]),
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleFiltersSubmit (filter) {
      this.filter = filter

      if (!this.filter.find(x => x.field === 'state')) {
        this.filter.push({ type: 'eq', field: 'state', value: 'active' })
      }

      this.isOpen = false

      return this.loadNextSourceIntegrations({ per_page: 25, page: 1 })
    },
    toggleFilters () {
      this.isOpen = !this.isOpen
    },
    handleSourceIntegrationsChange () {
      if (this.sourceIntegrationsPage <= 1) {
        this.loadedSourceIntegrations = this.sourceIntegrations
      } else {
        this.loadedSourceIntegrations = [
          ...this.loadedSourceIntegrations,
          ...this.sourceIntegrations
        ]
      }
    },
    loadNextSourceIntegrations (query) {
      this.isLoading = true

      query.filter = this.filter

      return this.loadSourceIntegrations(query)
        .then(() => {
          this.isLoading = false
        })
    },
    getIsVisible (item) {
      return item.id === (this.source || {}).id
        ? 'd-none'
        : ''
    },
    handleSetup (source) {
      this.source = source
      this.$refs.sourceModal.open(source)
    },
    handleSubmit (item) {
      this.$emit('submit', item)
    },
    handleSearchSubmit (search) {
      this.search = search

      const query = {
        page: 1,
        per_page: 25,
        search: this.search,
        filter: []
      }

      return this.loadNextSourceIntegrations(query)
    }
  }
}
</script>
